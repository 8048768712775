import { Modal } from 'bootstrap';

// Reguläre Ausdrücke als Konstanten
const DTSTART_REGEX = /DTSTART:(\d{8}T\d{6}Z)/;
const LOCATION_REGEX = /LOCATION:([^\n]*)/;
const CLASS_REGEX = /CLASS:(.*)/;
const STATUS_REGEX = /STATUS:(.*)/;

// Ersatz-Array für fehlerhafte Locations
const locationReplacements = {
    "97616 Bad Neustadt an der": "97616 Bad Neustadt an der Saale",
    "96160 Geise": "96160 Geiselwind",
    "07600 P": "Bierkönig Partykeller",
    "07600 El Arenal": "Bierkönig",
    // Weitere Ersatzwerte können hier hinzugefügt werden
};

function getCorrectedLocation(location) {
    return locationReplacements[location] || location;
}

// Funktion zum Umwandeln des .ics-Datumsformats in ein JavaScript-Datum
function convertICSToJSDate(icsDate) {
    const year = icsDate.substring(0, 4);
    const month = icsDate.substring(4, 6);
    const day = icsDate.substring(6, 8);
    return new Date(Date.UTC(year, month - 1, day));
}

// Funktion zum Formatieren des Datums im gewünschten Format
function formatDate(date) {
    const day = date.getUTCDate().toString().padStart(2, '0');
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const year = date.getUTCFullYear();
    return `${day}.${month}.${year}`;
}

// Funktion zum Auslesen der .ics-Datei und Extrahieren der gewünschten Daten
async function fetchSelectedDataFromICS(url) {
    const response = await fetch(url);
    const data = await response.text();
    const events = [];
    const eventBlocks = data.split('BEGIN:VEVENT');

    eventBlocks.forEach(block => {
        const startDateMatch = block.match(DTSTART_REGEX);
        const locationMatch = block.match(LOCATION_REGEX);
        const classMatch = block.match(CLASS_REGEX);
        const statusMatch = block.match(STATUS_REGEX);

        if (startDateMatch && (statusMatch && statusMatch[1].trim() === 'CONFIRMED')) {
            const startDate = convertICSToJSDate(startDateMatch[1].trim());
            let location = locationMatch ? locationMatch[1].replace(/\\,/g, ',').replace(/\\ /g, ' ') : 'Ort folgt'; // Ersetzen Sie die Escape-Zeichen
            location = getCorrectedLocation(location.split(',').pop().trim()); // Nur der letzte Teil und Korrektur
            events.push({
                startDate: startDate,
                location: location,
                class: classMatch ? classMatch[1].trim() : ''
            });
        }
    });

    // Filtern Sie Events, die in der Vergangenheit liegen
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const futureEvents = events.filter(event => event.startDate >= today);

    // Sortieren Sie die Events nach Datum
    futureEvents.sort((a, b) => a.startDate - b.startDate);

    // Fügen Sie die Klasse 'next' für das nächste Event hinzu
    if (futureEvents.length > 0) {
        futureEvents[0].next = true;
    }

    return futureEvents;
}

// Funktion zum Anzeigen der extrahierten Daten auf der Webseite
function displaySelectedData(events) {
    const container = document.getElementById('eventsContainer');
    events.forEach(event => {
        // Konvertieren Sie das Datum zurück in ein Date-Objekt, nachdem Sie es aus dem Local Storage geholt haben
        event.startDate = new Date(event.startDate);

        const eventDiv = document.createElement('div');
        eventDiv.className = 'event-item g-col-12 p-3 date-wrap';
        if (event.next) {
            eventDiv.classList.add('next');
        }

        const date = document.createElement('div');
        date.className = 'date';
        date.textContent = formatDate(event.startDate); // Datum im gewünschten Format

        const locationDiv = document.createElement('div');
        locationDiv.className = 'location';
        locationDiv.textContent = `${event.location}`;

        const eventClass = document.createElement('div');
        eventClass.className = 'summary';
        eventClass.textContent = `${event.class}`;

        eventDiv.appendChild(date);
        eventDiv.appendChild(locationDiv);
        eventDiv.appendChild(eventClass);
        container.appendChild(eventDiv);
    });
}

// Aufruf der Funktionen beim Laden der Seite
window.onload = async () => {
    let events = JSON.parse(localStorage.getItem('eventsData'));
    if (!events) {
        events = await fetchSelectedDataFromICS('https://calendar.bookitup.de/subscriptions/events/7e4a670c-d6d1-435c-825a-2d48ea8b6b77.ics');
        localStorage.setItem('eventsData', JSON.stringify(events));
    }
    displaySelectedData(events);

    // Aktualisieren Sie die Daten im Hintergrund
    const updatedEvents = await fetchSelectedDataFromICS('https://calendar.bookitup.de/subscriptions/events/7e4a670c-d6d1-435c-825a-2d48ea8b6b77.ics');
    localStorage.setItem('eventsData', JSON.stringify(updatedEvents));
};


